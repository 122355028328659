import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { NumberFormatInput, SelectDropdown, TextInput, PromotionSpecific } from '../../components';
import { ApplicationFlowLayout } from '../../layouts';
import {
    applicationState,
    createLeadResponseState,
    configurationResponseState,
    emailAddressState,
    firstNameState,
    gaDataLayerState,
    nidState,
    productIdBeforeDownsellState,
} from '../../store/atoms';
import {
    ApplicationModel,
    BasicInfoModel,
    CreateLeadRequest,
    OtpCheckProps,
    OtpMethods,
    PrimaryPhoneTypes,
    IncentiveFeatures,
} from '../../types';
import { isValidName, phonePattern, invalidNameCharacters } from '../../utils/validations';
import { isIncentiveFeatureActive, getPrn, getIncentive } from '../../utils/incentives';
import { generateSHA256Hash } from '../../utils/hashing';
import BasicInfoHelp from './basic-info-help';
import { useCreateLead } from './hooks';
import { routeHashes } from '../../app-routes';
import * as FullStory from '@fullstory/browser';
import { pushToDataLayer } from '../../utils/analytics';
import { currentCardInfo } from '../../utils/product-info';
import { SelectOptions } from '../../components/select-dropdown/types';
import { 
    creditcard,
    creditcardLaunch,
    creditcardPlusNoRibbon,
    iconBlueCheck
} from '../../assets/img';
import { debuglog } from '../../utils/debug';
import './index.scss';

const BasicInfo = () => {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [application, setApplication] = useRecoilState(applicationState);
    const [, setCreateLeadResponse] = useRecoilState(createLeadResponseState);
    const configurationResponse = useRecoilValue(configurationResponseState);
    const [, setEmailAddress] = useRecoilState(emailAddressState);
    const [, setFirstName] = useRecoilState(firstNameState);
    //const [, setUserId] = useRecoilState(userIdState);
    const [promoValueMinimumAmount, setPromoValueMinimumAmount] = useState<string>();
    const [promoValueDepositReduction, setPromoValueDepositReduction] = useState<string>();
    //const [incentiveName, setIncentiveName] = useState<string>();
    let [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);
    const [, setProductIdBeforeDownsell] = useRecoilState(productIdBeforeDownsellState);
    let nid = useRecoilValue(nidState);
    let cardSelected;

    const history = useHistory();
    const createLeadMutation = useCreateLead();
    debuglog('Basic-Info-Application', application);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required.')
            .test(
                'contains-alpha-characters-firstname',
                `First Name should only contain alphabetical characters.`,
                (value) => isValidName(value)
            )
            .default(() => application.firstName),
        lastName: Yup.string()
            .required('Last Name is required.')
            .test(
                'contains-alpha-characters-lastname',
                `Last Name should only contain alphabetical characters.`,
                (value) => isValidName(value)
            )
            .test(
                'firstLastMaxLength',
                'First Name and Last Name combined exceeds maximum characters.',
                function (item) {
                    return (this.parent.firstName.length + this.parent.lastName.length) < 27
                }
            )
            .default(() => application.lastName),
        emailAddress: Yup.string()
            .email('Invalid Email Address!')
            .required('Email Address is required.')
            .default(() => application.emailAddress),
        primaryPhone: Yup.string()
            .matches(phonePattern, 'Invalid Phone Number!')
            .required('Phone is required.')
            .default(() => application.primaryPhone),
        primaryPhoneType: Yup.string()
            .oneOf([PrimaryPhoneTypes.Mobile])
            .required()
            .default(
                () => PrimaryPhoneTypes.Mobile
            ),
        otpMethod: Yup.string()
            .oneOf([OtpMethods.Mobile, OtpMethods.Email])
            .required('Verification method is required.')
            .default(
                () => application.otpMethod ?? OtpMethods.Mobile
            ),
        phoneConsent: Yup.bool()
            .when("otpMethod", {
                is: OtpMethods.Mobile,
                then: Yup.bool().oneOf([true])
            })
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        handleSubmit,
        watch,
        register,
        formState: { errors, isValid },
    } = useForm<BasicInfoModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const onSubmit = async (event: BasicInfoModel) => {
        setEmailAddress(event.emailAddress ?? '');
        setFirstName(event.firstName ?? '');

        event.instanceId = Math.round(2000000000 * Math.random());

        /* TODO: include Launch */
        //BasicInfoNext Full Story Event 
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('BasicInfoNext', { leadId: application.leadId, productId: application.productId });
                cardSelected = 'Legacy';
                break;
            }
            case "plus": {
                FullStory.event('Plus_BasicInfoNext', { leadId: application.leadId, productId: application.productId });
                cardSelected = 'Plus';
                break;
            }
            case "launch": {
                FullStory.event('Launch_BasicInfoNext', { leadId: application.leadId });
                cardSelected = 'Launch';
                break;
            }
            default: {
                FullStory.event('BasicInfoNext', { leadId: application.leadId, productId: application.productId });
                cardSelected = 'Legacy';
                break;
            }
        }

        // Set the event's product id based on the card type chosen
        let curCard = currentCardInfo((application?.cardType ?? "basic"), configurationResponse?.securedProducts);
        event.productId = curCard.productId;

        if(event.productId){
            setProductIdBeforeDownsell(event.productId?.toString());
        }

        const incentive = getIncentive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse);

        

        setIsSubmitted(true);

        setApplication((current: ApplicationModel) => ({
            ...current,
            ...event,
            incentiveName: incentive?.name,
            incentiveId: incentive?.id,
        }));


        createLeadMutation.mutate(event as CreateLeadRequest, {
            onSuccess: (response) => {
                setApplication((current: ApplicationModel) => ({
                    ...current,
                    leadId: response.data.id,
                    instanceId: event.instanceId,
                }));

                /* TODO: include Launch card productId */
                // Add LeadId and ProductName to FS
                let productName = "";
                if (event.productId === 1) {
                    productName = "Secured";
                }
                else if (event.productId === 5) {
                    productName = "Secured Plus";
                }
                FullStory.identify(response.data.id, {
                    productName: productName
                })

                setCreateLeadResponse(response.data);
                history.push(routeHashes['/otp-check'], {
                    navigateTo: routeHashes['/debt-to-income'],
                    otpData: {
                        id: response.data.id,
                        isAppStatusCheck: false,
                        firstName: event.firstName,
                        emailAddress: event.emailAddress,
                        primaryPhone: event.primaryPhone,
                        otpMethod: response.data.otpMethod as OtpMethods,
                    },
                    otpSentTime: new Date(),
                    isInitialOtp: true,
                } as OtpCheckProps);
            },
        });

        const updateDataLayer = {
            ...gaDataLayer,
            'user_id': generateSHA256Hash(event?.emailAddress ?? ''),
            'NID_': nid,
            'step_name': 'About Me',
            'card_selected': cardSelected
        };

        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_0", updateDataLayer);
    };

    const primaryPhoneType = watch('primaryPhoneType');
    const primaryPhone = watch('primaryPhone');
    const emailAddress = watch('emailAddress');

    const [otpPhoneOption, setOtpPhoneOption] = useState<SelectOptions>({ value: OtpMethods.Mobile, label: '' });
    const [otpEmailOption, setOtpEmailOption] = useState<SelectOptions>({ value: OtpMethods.Email, label: '' });
    const [otpOptions, setOtpOptions] = useState<SelectOptions[]>([otpPhoneOption, otpEmailOption]);
    const [selectedOtpOption, setSelectedOtpOption] = useState<SelectOptions | undefined>(otpPhoneOption);

    // useEffect(() => {
    //     const incentiveDetail = getIncentiveDetail(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse);
    //     if (incentiveDetail?.incentiveTypeName == IncentiveFeatures.DepositReductionAmount) {
    //         setPromoValueDepositReduction(incentiveDetail.value);
    //     }
    //     if (incentiveDetail?.incentiveTypeName == IncentiveFeatures.MinimumDepositAmount) {
    //         setPromoValueMinimumAmount(incentiveDetail.value);
    //     }
        
    //     // console.log('Setting Incentive:', incentiveDetail);  
    //     // console.log(incentiveDetail?.incentiveTypeName);
    //     // setIncentiveName(incentiveDetail?.incentiveTypeName);
    // }, [incentiveName]);

    useEffect(() => {
        const indexOfAt = emailAddress?.indexOf('@') ?? 0;
        const dots = Math.floor(indexOfAt * .5);
        const remainingChars = indexOfAt - 1 - dots;
        
        const maskedEmail = `${emailAddress?.substring(0, 1)}${'•'.repeat(emailAddress?.substring(1, indexOfAt > 0 ? dots + 1 : emailAddress?.length).length ?? 0)}${emailAddress?.substring(indexOfAt > 0 ? indexOfAt - remainingChars : emailAddress?.length)}`
        setOtpEmailOption({ value: OtpMethods.Email, label: maskedEmail });
    }, [emailAddress]);

    useEffect(() => {
        const maskedPhoneNumber = `+1 ${'•'.repeat(primaryPhone?.substring(0, 3).length ?? 0)} ${'•'.repeat(primaryPhone?.substring(3, 6).length ?? 0)} ${primaryPhone?.substring(6, 10)}`
        setOtpPhoneOption({ value: OtpMethods.Mobile, label: maskedPhoneNumber });
    }, [primaryPhone]);

    useEffect(() => {
        const options = [otpPhoneOption, otpEmailOption];
        setOtpOptions(options);
    }, [otpEmailOption, otpPhoneOption]);

    return (
        <>
            <ApplicationFlowLayout
                showBackButton
                HelpComponent={BasicInfoHelp}
                progress="10%"
                cardType={application.cardType}
                currentStep="1"
                showBasicInfoFooter={true}
                promo={true}
                footerPreApprovedDisclosure={true}
                footerRewardsDisclosure={application.cardType !== "launch"}
                footerAlternateEligibleDisclosure={application.cardType === "basic" && isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("basic"), configurationResponse)}
                footerPlusEligibleDisclosure={application.cardType === "plus" && isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("plus"), configurationResponse)}
                promoPreApprovedDisclosureIndex='*'
                promoRewardsDisclosureIndex='**'
                promoAlternateEligibleDisclosureIndex='***'
                promoPlusEligibleDisclosureIndex='***'
            >
                <Row>
                    <Col>                            
                        <div id="header" className='header-margins'>
                            <h1>You’re <span style={{whiteSpace: 'nowrap'}}>Pre-Approved<sup>*</sup></span></h1>
                            <p className='inter-paragraph'><img src={iconBlueCheck} alt=""/> No credit check to apply.</p>
                            {application.cardType === 'plus' && (
                                <p className='inter-paragraph'><img src={iconBlueCheck} alt=""/> No annual fee.</p>
                            )}
                            {application.cardType !== 'launch' && (
                                <p className='inter-paragraph'><img src={iconBlueCheck} alt=""/> Up to 10% cash back rewards**</p>
                            )}
                        </div>
                        <div className="large-creditcard">
                            <img src={
                                application.cardType === 'basic' ? creditcard :
                                application.cardType === 'plus' ? creditcardPlusNoRibbon :
                                creditcardLaunch
                            } alt="" />
                        </div>
                        {application.cardType === 'launch' && (
                            <>
                                <div className="card-title">OpenSky Launch Visa®</div>
                            </>
                        )}
                        <PromotionSpecific
                            cardType={application.cardType}
                            incentiveFeatureName={IncentiveFeatures.MinimumDepositAmount}
                            config={configurationResponse}
                            setPromoValue={setPromoValueMinimumAmount}
                        >
                            <>                                    
                                <div className="limited-offer-container">                            
                                    <Button className="purple-button">
                                        Limited Time Offer***
                                    </Button>
                                    <div className="limited-offer-text">
                                    <span className="full-amount"> $300</span><span className="promo-amount"> ${promoValueMinimumAmount}</span> Minimum deposit
                                        <div className="limited-offer-subtext">Apply and fund today.</div>
                                    </div>
                                </div>                                    
                            </>                                               
                        </PromotionSpecific>
                        <PromotionSpecific
                            cardType={application.cardType}
                            incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                            config={configurationResponse}
                            setPromoValue={setPromoValueDepositReduction}
                        >
                            <>                                    
                                <div className="limited-offer-container">                            
                                    <Button className="purple-button">
                                        Limited Time Offer***
                                    </Button>
                                    <div className="limited-offer-text">
                                        Get a $200 Credit Line for<span className="promo-amount"> ${(200 - Number(promoValueDepositReduction))}.</span>
                                        <div className="limited-offer-subtext">Apply and fund today.</div>
                                    </div>
                                </div>                                    
                            </>                                               
                        </PromotionSpecific>                            
                        <Form>
                            <fieldset>
                                <legend className='legend-margins'>Personal information</legend>
                                <TextInput
                                    type="text"
                                    name="firstName"
                                    label="First Name"
                                    aria-label="First Name"
                                    errors={errors}
                                    register={register}
                                    maxLength="20"
                                    onChange={(event) =>
                                        event.target.value = event.target.value.replace(invalidNameCharacters, "")
                                    }
                                />
                                <TextInput
                                    type="text"
                                    name="lastName"
                                    label="Last Name"
                                    aria-label="Last Name"
                                    errors={errors}
                                    register={register}
                                    maxLength="20"
                                    onChange={(event) =>
                                        event.target.value = event.target.value.replace(invalidNameCharacters, "")
                                    }
                                />
                                <TextInput
                                    type="email"
                                    name="emailAddress"
                                    label="Email Address"
                                    aria-label="Email Address"
                                    errors={errors}
                                    register={register}
                                />
                                <NumberFormatInput
                                    name="primaryPhone"
                                    format="###-###-####"
                                    prefixText='+1 '
                                    label={`${primaryPhoneType === 'H' ? 'Home' : 'Mobile'} Phone Number`}
                                    errors={errors}
                                    register={register}
                                    type="tel"
                                    value={primaryPhone}
                                    style={{ paddingLeft: "48px" }}
                                />
                            </fieldset>
                            <fieldset>
                                <legend className='legend-margins'>How should we verify your account?</legend>
                                <SelectDropdown
                                    label={selectedOtpOption?.value === OtpMethods.Email ? 'Email Address' : 'Mobile Phone Number'}
                                    name="otpMethod"
                                    register={register}
                                    options={otpOptions}
                                    value={selectedOtpOption?.value}
                                    onChange={(value) => setSelectedOtpOption(otpOptions?.find(opt => opt.value === value))}
                                    errors={errors}
                                />
                                <Form.Group className='input-group' style={{ marginTop: '30px' }}>
                                    <Form.Check
                                        {...register('phoneConsent')}
                                        inline
                                        type='checkbox'
                                        label={
                                            <span className='form-check-span'>                                            
                                                By clicking "Next" below, I expressly consent to allow Capital Bank, directly or by third parties acting on its behalf,
                                                to send me marketing and promotional messages via text at the mobile number provided (Msg & Data rates may apply)
                                                or at the email address provided, about OpenSky products that I am applying for, and any other financial products offered by Capital Bank.
                                                I understand that my consent is not a condition of application and that I can opt out of promotional text messages and/or emails at any time.
                                                Message frequency may vary.
                                            </span>
                                        }
                                        name='phoneConsent'
                                        id='phoneConsent'
                                        defaultChecked={false}
                                    />
                                </Form.Group>
                            </fieldset>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        {!isSubmitted ? (
                            <Form.Group>
                                <Button
                                    type="submit"
                                    disabled={!isValid}
                                    onClick={handleSubmit(onSubmit)}
                                    data-testid='basic-info-next-button'
                                >
                                    Next
                                </Button>
                            </Form.Group>
                        ) : (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                    Processing...
                                </span>
                            </Spinner>
                        )}
                    </Col>
                </Row>
            </ApplicationFlowLayout>
        </>
    );
};

export default BasicInfo;
