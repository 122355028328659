import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { microserviceClient } from './api-clients';
import AppRoutes, { entryPoints, routeHashes } from './app-routes';
import { timeoutExpiredState, configurationResponseState, nidState, downsellSourcesState } from './store/atoms';
import { CardProductResponse, ConfigurationResponse, ErrorPageProps, ProductTypePrns, SetAffiliateInfoRequest, UtmResponse } from './types';
import { IdleTimerProvider } from 'react-idle-timer';
import './scss/app.scss';
import * as FullStory from '@fullstory/browser';
import TimeoutWarningPage from './pages/session-timeout/timeout-warning';
import { iconCircleError } from './assets/img';
import { debuglog } from './utils/debug';
import { CardTypes } from './utils/constants';

const sessionTimeoutMinutes = 15;
const promptBeforeIdleMinutes = 5;
const sessionTokenRefreshMinutes = 18;

const App = () => {
    FullStory.init({ orgId: 'o-1CMRN3-na1' });
    const history = useHistory();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search.toLowerCase());
    const [timeoutExpired, setTimeoutExpired] = useRecoilState(timeoutExpiredState);
    const setConfigurationResponse = useSetRecoilState(configurationResponseState);
    const [showTimeoutWarning, setShowTimeoutWarning] = useState(false);
    //const setUtmSourceName = useSetRecoilState(utmSourceNameState);
    const [, setNid] = useRecoilState(nidState);
    const [, setDownsellSources] = useRecoilState(downsellSourcesState);

    const cardTypesByPrn = (prn: ProductTypePrns) => {
        switch(prn){
            case ProductTypePrns.Launch:
                return CardTypes.launch;
            case ProductTypePrns.Plus:
                return CardTypes.plus;
            default:
                return CardTypes.basic;
        }
    }

    const getDownsellSources = (securedProducts: CardProductResponse[]): CardTypes[] => {
        const downsellSources: CardTypes[] = [];

        for(const product of securedProducts){
            const hasDownsell = product.incentives?.some(incentive => 
                incentive.incentiveDetails.some(detail => 
                    detail.incentiveTypeName.toLowerCase() === "downsell"
                )
            );

            if(hasDownsell){
                downsellSources.push(cardTypesByPrn(product.prn as ProductTypePrns));
            }
        }

        return downsellSources;
    }

    useEffect(() => {
        //Identify Application Pages
        const pathNameLower = window?.location?.pathname.toLowerCase();
        const urlHref = new URL(window.location.href.toString());
        const urlHrefParams = new URLSearchParams(urlHref.search);

        const configUrlParams = new URLSearchParams();

        if (Array.from(urlHref.searchParams).length > 0) {
            configUrlParams.set('utmSourceName', urlHrefParams.get('utm_source') ?? '');
            configUrlParams.set('utmMediumName', urlHrefParams.get('utm_medium') ?? '');
            configUrlParams.set('utmCampaignName', urlHrefParams.get('utm_campaign') ?? '');
            configUrlParams.set('cid', urlHrefParams.get('cid') ?? '');            
            configUrlParams.set('nid', urlHrefParams.get('nid') ?? '');            
            console.log('Params:', configUrlParams.toString());      
        }

        // setUtmSourceName(configUrlParams.get('utmSourceName') ?? '');

        if (pathNameLower !== null && pathNameLower.trim() !== "") {
            if ((pathNameLower.includes('securedplus/apply') || pathNameLower.includes('secured/apply') || pathNameLower.toLowerCase().includes('opensky/apply'))) {
                lookupNidsAndUpdateUrl();
            } else if (!entryPoints.includes(pathNameLower)) {
                history.replace(routeHashes['/OpenSky/Apply']);
            }
        }

        setNid(configUrlParams.get('nid') ?? '');
        
        // load the configuration
        microserviceClient
            .get<ConfigurationResponse>('/api/configuration?' + configUrlParams.toString())
            .then((response) => {
                (window as any).debug = true;
                if (!!response?.data) {
                    //indicate this config is NOT a default pre-loaded version
                    response.data.loaded = true;
                    if (response && response.data && response.data.domainBaseUrl && (
                        !response.data.domainBaseUrl.includes('loc') &&
                        !response.data.domainBaseUrl.includes('dev') &&
                        !response.data.domainBaseUrl.includes('uat') &&
                        !queryString.has('debug'))) {                            
                            (window as any).debug = false;
                    }
                }
                console.log('Debugging is: ' + ((window as any).debug ? 'enabled': 'disabled'));
                if (!!response?.data?.securedProducts) {
                    response.data.securedProducts.forEach(md => {
                        md.minimumDeposit = (!!md.minimumDeposit) ? md.minimumDeposit : md.minimumCreditLimit;
                    });
                }
                
                debuglog('Configuration:', response.data);
                setConfigurationResponse(response.data);
            }).catch((err) => console.log(err))
            .finally(() => {
                // get csrf/jwt cookie
                microserviceClient.get('/api/security/startSession').catch((err) => console.log(err));
            });

            configUrlParams.set('downsell', 'true');

            microserviceClient
            .get<ConfigurationResponse>('/api/configuration?' + configUrlParams.toString())
            .then((response) => {
                (window as any).debug = true;
                if (!!response?.data) {
                    //indicate this config is NOT a default pre-loaded version
                    response.data.loaded = true;
                    if (response && response.data && response.data.domainBaseUrl && (
                        !response.data.domainBaseUrl.includes('loc') &&
                        !response.data.domainBaseUrl.includes('dev') &&
                        !response.data.domainBaseUrl.includes('uat') &&
                        !queryString.has('debug'))) {                            
                            (window as any).debug = false;
                    }
                }
                console.log('Debugging is: ' + ((window as any).debug ? 'enabled': 'disabled'));
                if (!!response?.data?.securedProducts) {
                    setDownsellSources(getDownsellSources(response.data.securedProducts));
                }
                
                debuglog('Configuration:', response.data);
            }).catch((err) => console.log(err))
            .finally(() => {
                // get csrf/jwt cookie
                microserviceClient.get('/api/security/startSession').catch((err) => console.log(err));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    function lookupNidsAndUpdateUrl() {
        if (queryString.has('NID'.toLowerCase()) || queryString.has('SID'.toLowerCase())) {
            const payload: SetAffiliateInfoRequest = {
                Nid: queryString.get('NID'.toLowerCase()),
                Sid: queryString.get('SID'.toLowerCase()),
                Aid: queryString.get('AID'.toLowerCase()),
                Bid: queryString.get('BID'.toLowerCase()),
                Cid: queryString.get('CID'.toLowerCase()),
                Pid: queryString.get('PID'.toLowerCase()),
            };

            //Object defines the key names for query params
            const utmKeyMapper: UtmResponse = {
                nid: 'NID',
                sid: 'SID',
                bid: 'BID',
                utmSource: 'utm_source',
                utmMedium: 'utm_medium',
                utmContent: 'utm_content',
                utmCampaign: 'utm_campaign'
            }
            //Get Utm Values from API
            microserviceClient
                .post<UtmResponse>('/api/application/setAffiliateInfo', payload)
                .then(response => {
                    const utmData = response.data;
                    if (utmData != null) {
                        const filteredObj = Object.fromEntries(Object.entries(utmData).filter(([, val]) => val != null));
                        const queryParams = Object.keys(filteredObj)
                            .map(key => key.replace(key, (utmKeyMapper as any)[key]) + '=' + (utmData as any)[key]).join('&');
                        //Update url and redirect
                        const pathName = window?.location?.pathname;
                        if (pathName !== null && pathName.trim() !== "") {
                            if (pathName.toLowerCase() === '/secured/apply' && queryString.has('default') && queryString.get('default') === 'true') {
                                window.location.href = `/dotcom-pages/homepage.html?${queryParams}`
                            }
                            else {
                                history.replace(`${pathName}?${queryParams}`);
                            }
                        }
                    }
                })
                .catch(err => {
                    //continue with app
                })
        }
    };

    // useEffect(() => {
    //     const fsCurrentSession = FullStory.getCurrentSessionURL();
    //     //FullStory.log(FullStory.LogLevel.Info, 'Login info')

    //     console.log(fsCurrentSession);
    //     // Client-side timeout after 15 minutes (update on lead submit and app reload)
    //     const sessionTimeout = window.setTimeout(() => {
    //         // Expires the timeout, redirect will happen on page-load
    //         setTimeoutExpired(true);
    //         history.replace(routeHashes['/error'], {
    //             title: 'For security reasons, your session with OpenSky has expired.',
    //             buttonText: 'Ok, Got it',
    //             navigateTo: process.env.REACT_APP_HOMEPAGE_URL,
    //         } as ErrorPageProps);
    //     }, (sessionTimeoutMinutes * 60 * 1000));

    //     return () => window.clearTimeout(sessionTimeout);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [leadSubmitted]);
    
    const onIdle = () => {
        // Client-side timeout after 15 minutes of inactivity
        setTimeoutExpired(true);
        setShowTimeoutWarning(false);
        history.replace(routeHashes['/error'], {
            title: 'For security reasons, your session with OpenSky has expired.',
            buttonText: 'Ok, Got it',
            navigateTo: process.env.REACT_APP_HOMEPAGE_URL,
            errorIcon: iconCircleError,
        } as ErrorPageProps);
    };

    const onPrompt = () => {
        // Show timeout warning message and with a chance to stay connected.        
        setShowTimeoutWarning(true);
    };

    const onActive = () => {
        setShowTimeoutWarning(false);
    }
    
    useEffect(() => {
        //Keep backend session alive if the UI session is active
        let sessionRefresh: number | undefined = undefined;
        if (!timeoutExpired) {
            sessionRefresh = window.setInterval(() => {
                microserviceClient.get('/api/security/refreshSession').catch((err) => console.log(err));
            }, (sessionTokenRefreshMinutes * 60 * 1000));
        } else {
            window.clearInterval(sessionRefresh);
        }

        return () => {
            window.clearInterval(sessionRefresh);
        }
    }, [timeoutExpired]);
    
    return (
        <div className="app">
            <input id="env" type="hidden" value={process.env.REACT_APP_ENVCHECK} />
            <IdleTimerProvider
                timeout={sessionTimeoutMinutes * 60 * 1000}
                promptBeforeIdle={promptBeforeIdleMinutes * 60 * 1000}
                throttle={500}
                onPrompt={onPrompt}
                onIdle={onIdle}
                onActive={onActive}
                stopOnIdle={true}
            >
                <div style={{ display: showTimeoutWarning ? 'none' : 'block' }}>
                    <AppRoutes />
                </div>               
                <TimeoutWarningPage showPrompt={showTimeoutWarning} />
            </IdleTimerProvider>           
        </div>
    );
};

export default App;
