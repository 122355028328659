const files = {
    electronicDisclosure: ['/files/electronic_disclosure_consent_statement.pdf'],
    currentBasicCardHolderAgreement: ['/files/ca-o-067.pdf'],
    currentBasicTermsConditions: ['/files/tc-sky1-067.pdf'],    
    currentCardHolderAgreement: ['/files/ca-o-067.pdf'],
    currentTermsConditions: ['/files/tc-sky1-067.pdf'],    
    privacyNotice: ['/files/Capital-Bank-Online-Privacy-Policy-rev-01-2018_final.pdf'],

    currentPlusCardHolderAgreement: ['/files/ca-p-012.pdf'],
    currentPlusTermsConditions: ['/files/tc-sky1p-012.pdf'],
    
    currentLaunchCardHolderAgreement: ['/files/ca-l-006.pdf'],
    currentLaunchTermsConditions: ['/files/tc-sky1l-006.pdf'],
    
    privacyPolicy: ['/files/Privacy-Notice-Disclosure-2024-October.pdf'],

    currentRewardsTermsConditions: ['/files/OpenSky_Rewards_TermsandConditions.pdf'],
};
// NOTE: delete currentCardHolderAgreement and currentTermsConditions after SecuredPlus feature flag is removed

export class Files {
    static GetFiles() {
        return files;
    }
}
