import React, { useEffect } from "react";
import { AffiliateLogo, CardOffer } from "../../components";
import { currentCardInfo } from "../../utils/product-info";
import { useRecoilState, useRecoilValue } from "recoil";
import { applicationBeforeDownsellState, applicationState, configurationResponseState, downsellState, productIdBeforeDownsellState, utmSourceNameState } from "../../store/atoms";
import { CardTypes } from "../../utils/constants";
import { Modal } from "react-bootstrap";
import './downsell.scss';
import { logoOpenskyNoPadding, iconChevronLeft } from "../../assets/img";
import { useHistory } from "react-router";
import { ProductTypePrns } from "../../types";
import { postProductUpdate } from "../credit-line/hooks";

const Downsell = () => {
    const history = useHistory();

    const configurationResponse = useRecoilValue(configurationResponseState);
    const launchCardInfo = currentCardInfo(CardTypes.launch, configurationResponse?.securedProducts);
    const utmSourceName = useRecoilValue(utmSourceNameState);
    const [application, setApplication] = useRecoilState(applicationState);
    const [applicationBeforeDownsell, setApplicationBeforeDownsell] = useRecoilState(applicationBeforeDownsellState);
    const [downsell, setDownsell] = useRecoilState(downsellState);
    const productIdBeforeDownsell = useRecoilValue(productIdBeforeDownsellState);
    
    const launchMinimumDeposit = 100;
    const launchCreditLimit = 100;
    const launchAnnualFee = 24;
    const launchProgramFee = 19.95;
    const launchSecurityDeposit = 100;

    useEffect(() => {
        if(!downsell){
            setApplicationBeforeDownsell(application);
        }
        else if(applicationBeforeDownsell !== null){
            setApplication(applicationBeforeDownsell);
        }

        postProductUpdate(application?.leadId, productIdBeforeDownsell);

        setDownsell(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAcceptOffer = () => {
        setApplication((current) => ({
            ...current,
            ...{ 
                amount: launchMinimumDeposit,
                cardType: CardTypes.launch,
                productId: parseInt(ProductTypePrns.Launch),
                annualFee: launchAnnualFee.toString(),
                programFee: launchProgramFee,
                creditLimit: launchCreditLimit,
                defaultCreditLimit: launchCreditLimit,
                securityDeposit: launchSecurityDeposit,
                fundToday: true,
                incentiveId: undefined,
                incentiveName: undefined,
                downsellFrom: CardTypes.plus
             },
        }));

        postProductUpdate(application?.leadId, launchCardInfo?.productId?.toString() ?? '0');

        setDownsell(true);

        history.push('/id-confirm');
    }

    const handleClose = () => {
        history.push('/terms-and-conditions');
    }

    const handleGoBack = () => {
        history.goBack();
    }

    return (
            <Modal show={true} className="center-modal" contentClassName="modal-dialog-dimensions" onHide={handleClose} backdrop={'static'}>
                <Modal.Header closeButton className="modal-header-dimensions">
                    <button className="link-button" onClick={() => handleGoBack()}>
                        <img src={iconChevronLeft} alt="Back" />
                    </button>
                    <div className="downsell-navbar-logo">
                        <img src={logoOpenskyNoPadding} alt="OpenSky" className="opensky-logo-dimensions" />
                        <div className="divider">&nbsp;<span></span>&nbsp;</div>
                        <AffiliateLogo source={utmSourceName}></AffiliateLogo>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <h1 className="downsell-heading">Not ready to fund <br/>${application.amount}, try our NEW Launch Secured Card instead and fund $100 today!</h1>
                    <CardOffer minimumDeposit={launchMinimumDeposit}
                    apr={launchCardInfo.annualPercentageRate}
                    tclink={launchCardInfo.currentTermsConditions}
                    cardType={CardTypes.launch}
                    annualFee={launchAnnualFee}
                    onAcceptOffer={handleAcceptOffer}
                    onClose={handleClose}/>
                </Modal.Body>
                <Modal.Footer>
                    <p className="footer-text">
                        *The OpenSky Launch Secured Visa Credit Card requires a monthly fee.
                        The monthly fee is billed at a rate of $2 per month, annualized at $24 for the first 12 months.
                        Beginning year two and for as long as the account remains open, the monthly fee is billed at a rate of $3 per month, annualized at $36.
                    </p>
                </Modal.Footer>
            </Modal>);
}
 
export default Downsell;