import React from "react";
import { getIncentiveDetail, getPrn, isIncentiveFeatureActive } from "../../utils/incentives";
import { IncentiveFeatures } from "../../types/enums";
import { configurationResponseState } from "../../store/atoms";
import { useRecoilValue } from "recoil";
import { Card } from "react-bootstrap";
import { creditcardLaunch, iconArrowWhite } from "../../assets/img";
import { CardTypes } from "../../utils/constants";
import './card-offer.scss';

interface CardOfferProps {
    minimumDeposit: number;
    apr: number;
    tclink: string;
    annualFee: number;
    cardType: CardTypes;
    onAcceptOffer: () => void;
    onClose: () => void;
}

const CardOffer = (props: CardOfferProps) => {
    const configurationResponse = useRecoilValue(configurationResponseState);

    const getMinimumDepositBasedOnIncentives = () => {
        if(isIncentiveFeatureActive(IncentiveFeatures.DepositReductionAmount, getPrn(props.cardType.toLowerCase()), configurationResponse)){
            return getIncentiveDetail(IncentiveFeatures.DepositReductionAmount,
                                      getPrn(props.cardType.toLowerCase()),
                                      configurationResponse)?.value;
        }
        else if(isIncentiveFeatureActive(IncentiveFeatures.MinimumDepositAmount, getPrn(props.cardType.toLowerCase()), configurationResponse)){
            return getIncentiveDetail(IncentiveFeatures.MinimumDepositAmount, getPrn(props.cardType.toLowerCase()), configurationResponse)?.value
        }
        else {
            return props.minimumDeposit
        }
    }

    return (<Card className="card-offer-container">
                <Card.Body>
                    <div className="card-details-container">
                        <img src={creditcardLaunch} className="card" alt="launch card"></img>
                        <div>
                            <h2 className="card-offer-title">OpenSky<br/> Launch Visa®</h2>
                            <p className="card-offer-description">Secured Visa® Credit Card</p>
                        </div>
                    </div>
                    <div className="col">
                        <p className="card-offer-paragraph">Fund ${getMinimumDepositBasedOnIncentives()} to get started on your <br/>credit building journey. 
                            First year <br/> annual fee of ${props.annualFee} billed monthly at <br/> $2 a month.*</p>
                    </div>
                    <div className="center-content">
                        <button className="card-offer-button" onClick={props.onAcceptOffer}>
                            <p className="card-offer-button-text">Accept offer</p>
                            <img alt="arrow" src={iconArrowWhite}/>
                        </button>
                        <button className="close-button" onClick={props.onClose}>Close</button>
                    </div>
                    <div className="benefits-table">
                            {isIncentiveFeatureActive(IncentiveFeatures.DepositReductionAmount, getPrn(props.cardType.toLowerCase()), configurationResponse) && ( 
                                <div className="benefit-row">
                                    <span className="benefit-description">Minimum security deposit</span>
                                    <span className="benefit-value">
                                        ${(props.minimumDeposit) - 
                                        (Number(
                                            getIncentiveDetail(IncentiveFeatures.DepositReductionAmount, getPrn(props.cardType.toLowerCase())
                                            , configurationResponse)?.value ?? 0) ?? 0)}
                                    </span>
                                </div>                                    
                            )}
                            {isIncentiveFeatureActive(IncentiveFeatures.MinimumDepositAmount, getPrn(props.cardType.toLowerCase()), configurationResponse) && (
                                <div className="benefit-row"> 
                                    <span className="benefit-description">Minimum security deposit</span>
                                    <span>
                                        <span className="gray-strike">${props.minimumDeposit}</span>
                                        <span className="benefit-value"> 
                                            ${(getIncentiveDetail(IncentiveFeatures.MinimumDepositAmount, getPrn(props.cardType.toLowerCase()),
                                            configurationResponse)?.value ?? 'N/A')}
                                        </span>
                                    </span>
                                </div>
                            )}
                            {!isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(props.cardType.toLowerCase()), configurationResponse) && (
                                <>
                                    <div className="benefit-row">
                                        <span className="benefit-description">Minimum security deposit</span>
                                        <span className="benefit-value">${props.minimumDeposit}</span>
                                    </div>
                                    <div className="divider"/>
                                </>
                            )}
                            <div className="benefit-row">
                                <span className="benefit-description">Monthly fee*</span>
                                <span className="benefit-value">$2</span>
                            </div>
                            <div className="divider"/>
                            <div className="benefit-row">
                                <span className="benefit-description">No credit check to apply</span>
                            </div>
                    </div>
                    <div className="card-bottom-container">
                        <p className="card-bottom-text">{props.apr}% Annual percentage rate</p>
                        <a href={props.tclink} target="_blank" rel="noopener noreferrer" className="card-bottom-text-underline">+See terms and conditions</a>
                    </div>
                </Card.Body>
            </Card>);
}
 
export default CardOffer;